/**
 * Generated by orval v6.5.1 🍺
 * Do not edit manually.
 * Navtrack.Api
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  ProblemDetails,
  ChangePasswordModel,
  RegisterAccountModel,
  AssetsModel,
  AssetModel,
  ErrorModel,
  AddAssetModel,
  UpdateAssetModel,
  LocationListModel,
  GetAssetsAssetIdLocationsParams,
  AssetUserListModel,
  AddUserToAssetModel,
  TripListModel,
  GetAssetsAssetIdTripsParams,
  GetAssetsAssetIdReportsTimedistanceParams,
  DevicesModel,
  AddDeviceModel,
  DeviceTypesModel,
  ProtocolsModel,
  GetSettings200,
  CurrentUserModel,
  UpdateUserModel
} from './model/generated'
import { authAxiosInstance } from './authAxiosInstance'


type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


export const postAccountPassword = (
    changePasswordModel: ChangePasswordModel,
 ) => {
      return authAxiosInstance<void>(
      {url: `/account/password`, method: 'post',
      data: changePasswordModel
    },
      );
    }
  


    export const usePostAccountPassword = <TError = ProblemDetails,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postAccountPassword>, TError,{data: ChangePasswordModel}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postAccountPassword>, {data: ChangePasswordModel}> = (props) => {
          const {data} = props || {};

          return  postAccountPassword(data,)
        }

      return useMutation<AsyncReturnType<typeof postAccountPassword>, TError, {data: ChangePasswordModel}, TContext>(mutationFn, mutationOptions)
    }
    
export const postAccount = (
    registerAccountModel: RegisterAccountModel,
 ) => {
      return authAxiosInstance<void>(
      {url: `/account`, method: 'post',
      data: registerAccountModel
    },
      );
    }
  


    export const usePostAccount = <TError = ProblemDetails,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postAccount>, TError,{data: RegisterAccountModel}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postAccount>, {data: RegisterAccountModel}> = (props) => {
          const {data} = props || {};

          return  postAccount(data,)
        }

      return useMutation<AsyncReturnType<typeof postAccount>, TError, {data: RegisterAccountModel}, TContext>(mutationFn, mutationOptions)
    }
    
export const getAssets = (
    
 ) => {
      return authAxiosInstance<AssetsModel>(
      {url: `/assets`, method: 'get'
    },
      );
    }
  

export const getGetAssetsQueryKey = () => [`/assets`];

    
export const useGetAssets = <TData = AsyncReturnType<typeof getAssets>, TError = ProblemDetails>(
  options?: { query?:UseQueryOptions<AsyncReturnType<typeof getAssets>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetAssetsQueryKey();

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getAssets>> = () => getAssets();

  const query = useQuery<AsyncReturnType<typeof getAssets>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postAssets = (
    addAssetModel: AddAssetModel,
 ) => {
      return authAxiosInstance<AssetModel>(
      {url: `/assets`, method: 'post',
      data: addAssetModel
    },
      );
    }
  


    export const usePostAssets = <TError = ErrorModel,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postAssets>, TError,{data: AddAssetModel}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postAssets>, {data: AddAssetModel}> = (props) => {
          const {data} = props || {};

          return  postAssets(data,)
        }

      return useMutation<AsyncReturnType<typeof postAssets>, TError, {data: AddAssetModel}, TContext>(mutationFn, mutationOptions)
    }
    
export const getAssetsAssetId = (
    assetId: string,
 ) => {
      return authAxiosInstance<AssetModel>(
      {url: `/assets/${assetId}`, method: 'get'
    },
      );
    }
  

export const getGetAssetsAssetIdQueryKey = (assetId: string,) => [`/assets/${assetId}`];

    
export const useGetAssetsAssetId = <TData = AsyncReturnType<typeof getAssetsAssetId>, TError = ProblemDetails>(
 assetId: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getAssetsAssetId>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetAssetsAssetIdQueryKey(assetId);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getAssetsAssetId>> = () => getAssetsAssetId(assetId, );

  const query = useQuery<AsyncReturnType<typeof getAssetsAssetId>, TError, TData>(queryKey, queryFn, {enabled: !!(assetId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const patchAssetsAssetId = (
    assetId: string,
    updateAssetModel: UpdateAssetModel,
 ) => {
      return authAxiosInstance<void>(
      {url: `/assets/${assetId}`, method: 'patch',
      data: updateAssetModel
    },
      );
    }
  


    export const usePatchAssetsAssetId = <TError = ProblemDetails,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof patchAssetsAssetId>, TError,{assetId: string;data: UpdateAssetModel}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof patchAssetsAssetId>, {assetId: string;data: UpdateAssetModel}> = (props) => {
          const {assetId,data} = props || {};

          return  patchAssetsAssetId(assetId,data,)
        }

      return useMutation<AsyncReturnType<typeof patchAssetsAssetId>, TError, {assetId: string;data: UpdateAssetModel}, TContext>(mutationFn, mutationOptions)
    }
    
export const deleteAssetsAssetId = (
    assetId: string,
 ) => {
      return authAxiosInstance<void>(
      {url: `/assets/${assetId}`, method: 'delete'
    },
      );
    }
  


    export const useDeleteAssetsAssetId = <TError = ProblemDetails,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof deleteAssetsAssetId>, TError,{assetId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof deleteAssetsAssetId>, {assetId: string}> = (props) => {
          const {assetId} = props || {};

          return  deleteAssetsAssetId(assetId,)
        }

      return useMutation<AsyncReturnType<typeof deleteAssetsAssetId>, TError, {assetId: string}, TContext>(mutationFn, mutationOptions)
    }
    
export const getAssetsAssetIdLocations = (
    assetId: string,
    params?: GetAssetsAssetIdLocationsParams,
 ) => {
      return authAxiosInstance<LocationListModel>(
      {url: `/assets/${assetId}/locations`, method: 'get',
        params,
    },
      );
    }
  

export const getGetAssetsAssetIdLocationsQueryKey = (assetId: string,
    params?: GetAssetsAssetIdLocationsParams,) => [`/assets/${assetId}/locations`, ...(params ? [params]: [])];

    
export const useGetAssetsAssetIdLocations = <TData = AsyncReturnType<typeof getAssetsAssetIdLocations>, TError = ProblemDetails>(
 assetId: string,
    params?: GetAssetsAssetIdLocationsParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getAssetsAssetIdLocations>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetAssetsAssetIdLocationsQueryKey(assetId,params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getAssetsAssetIdLocations>> = () => getAssetsAssetIdLocations(assetId,params, );

  const query = useQuery<AsyncReturnType<typeof getAssetsAssetIdLocations>, TError, TData>(queryKey, queryFn, {enabled: !!(assetId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const getAssetsAssetIdUsers = (
    assetId: string,
 ) => {
      return authAxiosInstance<AssetUserListModel>(
      {url: `/assets/${assetId}/users`, method: 'get'
    },
      );
    }
  

export const getGetAssetsAssetIdUsersQueryKey = (assetId: string,) => [`/assets/${assetId}/users`];

    
export const useGetAssetsAssetIdUsers = <TData = AsyncReturnType<typeof getAssetsAssetIdUsers>, TError = ProblemDetails>(
 assetId: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getAssetsAssetIdUsers>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetAssetsAssetIdUsersQueryKey(assetId);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getAssetsAssetIdUsers>> = () => getAssetsAssetIdUsers(assetId, );

  const query = useQuery<AsyncReturnType<typeof getAssetsAssetIdUsers>, TError, TData>(queryKey, queryFn, {enabled: !!(assetId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const postAssetsAssetIdUsers = (
    assetId: string,
    addUserToAssetModel: AddUserToAssetModel,
 ) => {
      return authAxiosInstance<void>(
      {url: `/assets/${assetId}/users`, method: 'post',
      data: addUserToAssetModel
    },
      );
    }
  


    export const usePostAssetsAssetIdUsers = <TError = ErrorModel,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postAssetsAssetIdUsers>, TError,{assetId: string;data: AddUserToAssetModel}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postAssetsAssetIdUsers>, {assetId: string;data: AddUserToAssetModel}> = (props) => {
          const {assetId,data} = props || {};

          return  postAssetsAssetIdUsers(assetId,data,)
        }

      return useMutation<AsyncReturnType<typeof postAssetsAssetIdUsers>, TError, {assetId: string;data: AddUserToAssetModel}, TContext>(mutationFn, mutationOptions)
    }
    
export const deleteAssetsAssetIdUsersUserId = (
    assetId: string,
    userId: string,
 ) => {
      return authAxiosInstance<void>(
      {url: `/assets/${assetId}/users/${userId}`, method: 'delete'
    },
      );
    }
  


    export const useDeleteAssetsAssetIdUsersUserId = <TError = ErrorModel,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof deleteAssetsAssetIdUsersUserId>, TError,{assetId: string;userId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof deleteAssetsAssetIdUsersUserId>, {assetId: string;userId: string}> = (props) => {
          const {assetId,userId} = props || {};

          return  deleteAssetsAssetIdUsersUserId(assetId,userId,)
        }

      return useMutation<AsyncReturnType<typeof deleteAssetsAssetIdUsersUserId>, TError, {assetId: string;userId: string}, TContext>(mutationFn, mutationOptions)
    }
    
export const getAssetsAssetIdTrips = (
    assetId: string,
    params?: GetAssetsAssetIdTripsParams,
 ) => {
      return authAxiosInstance<TripListModel>(
      {url: `/assets/${assetId}/trips`, method: 'get',
        params,
    },
      );
    }
  

export const getGetAssetsAssetIdTripsQueryKey = (assetId: string,
    params?: GetAssetsAssetIdTripsParams,) => [`/assets/${assetId}/trips`, ...(params ? [params]: [])];

    
export const useGetAssetsAssetIdTrips = <TData = AsyncReturnType<typeof getAssetsAssetIdTrips>, TError = ProblemDetails>(
 assetId: string,
    params?: GetAssetsAssetIdTripsParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getAssetsAssetIdTrips>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetAssetsAssetIdTripsQueryKey(assetId,params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getAssetsAssetIdTrips>> = () => getAssetsAssetIdTrips(assetId,params, );

  const query = useQuery<AsyncReturnType<typeof getAssetsAssetIdTrips>, TError, TData>(queryKey, queryFn, {enabled: !!(assetId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const getAssetsAssetIdReportsTimedistance = (
    assetId: string,
    params?: GetAssetsAssetIdReportsTimedistanceParams,
 ) => {
      return authAxiosInstance<TripListModel>(
      {url: `/assets/${assetId}/reports/time-distance`, method: 'get',
        params,
    },
      );
    }
  

export const getGetAssetsAssetIdReportsTimedistanceQueryKey = (assetId: string,
    params?: GetAssetsAssetIdReportsTimedistanceParams,) => [`/assets/${assetId}/reports/time-distance`, ...(params ? [params]: [])];

    
export const useGetAssetsAssetIdReportsTimedistance = <TData = AsyncReturnType<typeof getAssetsAssetIdReportsTimedistance>, TError = ProblemDetails>(
 assetId: string,
    params?: GetAssetsAssetIdReportsTimedistanceParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getAssetsAssetIdReportsTimedistance>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetAssetsAssetIdReportsTimedistanceQueryKey(assetId,params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getAssetsAssetIdReportsTimedistance>> = () => getAssetsAssetIdReportsTimedistance(assetId,params, );

  const query = useQuery<AsyncReturnType<typeof getAssetsAssetIdReportsTimedistance>, TError, TData>(queryKey, queryFn, {enabled: !!(assetId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const getAssetsAssetIdDevices = (
    assetId: string,
 ) => {
      return authAxiosInstance<DevicesModel>(
      {url: `/assets/${assetId}/devices`, method: 'get'
    },
      );
    }
  

export const getGetAssetsAssetIdDevicesQueryKey = (assetId: string,) => [`/assets/${assetId}/devices`];

    
export const useGetAssetsAssetIdDevices = <TData = AsyncReturnType<typeof getAssetsAssetIdDevices>, TError = ProblemDetails>(
 assetId: string, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getAssetsAssetIdDevices>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetAssetsAssetIdDevicesQueryKey(assetId);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getAssetsAssetIdDevices>> = () => getAssetsAssetIdDevices(assetId, );

  const query = useQuery<AsyncReturnType<typeof getAssetsAssetIdDevices>, TError, TData>(queryKey, queryFn, {enabled: !!(assetId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const postAssetsAssetIdDevices = (
    assetId: string,
    addDeviceModel: AddDeviceModel,
 ) => {
      return authAxiosInstance<void>(
      {url: `/assets/${assetId}/devices`, method: 'post',
      data: addDeviceModel
    },
      );
    }
  


    export const usePostAssetsAssetIdDevices = <TError = ProblemDetails,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof postAssetsAssetIdDevices>, TError,{assetId: string;data: AddDeviceModel}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof postAssetsAssetIdDevices>, {assetId: string;data: AddDeviceModel}> = (props) => {
          const {assetId,data} = props || {};

          return  postAssetsAssetIdDevices(assetId,data,)
        }

      return useMutation<AsyncReturnType<typeof postAssetsAssetIdDevices>, TError, {assetId: string;data: AddDeviceModel}, TContext>(mutationFn, mutationOptions)
    }
    
export const deleteAssetsAssetIdDevicesDeviceId = (
    assetId: string,
    deviceId: string,
 ) => {
      return authAxiosInstance<void>(
      {url: `/assets/${assetId}/devices/${deviceId}`, method: 'delete'
    },
      );
    }
  


    export const useDeleteAssetsAssetIdDevicesDeviceId = <TError = ProblemDetails,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof deleteAssetsAssetIdDevicesDeviceId>, TError,{assetId: string;deviceId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof deleteAssetsAssetIdDevicesDeviceId>, {assetId: string;deviceId: string}> = (props) => {
          const {assetId,deviceId} = props || {};

          return  deleteAssetsAssetIdDevicesDeviceId(assetId,deviceId,)
        }

      return useMutation<AsyncReturnType<typeof deleteAssetsAssetIdDevicesDeviceId>, TError, {assetId: string;deviceId: string}, TContext>(mutationFn, mutationOptions)
    }
    
export const getDevicesTypes = (
    
 ) => {
      return authAxiosInstance<DeviceTypesModel>(
      {url: `/devices/types`, method: 'get'
    },
      );
    }
  

export const getGetDevicesTypesQueryKey = () => [`/devices/types`];

    
export const useGetDevicesTypes = <TData = AsyncReturnType<typeof getDevicesTypes>, TError = ProblemDetails>(
  options?: { query?:UseQueryOptions<AsyncReturnType<typeof getDevicesTypes>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetDevicesTypesQueryKey();

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getDevicesTypes>> = () => getDevicesTypes();

  const query = useQuery<AsyncReturnType<typeof getDevicesTypes>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getHealth = (
    
 ) => {
      return authAxiosInstance<void>(
      {url: `/health`, method: 'get'
    },
      );
    }
  

export const getGetHealthQueryKey = () => [`/health`];

    
export const useGetHealth = <TData = AsyncReturnType<typeof getHealth>, TError = unknown>(
  options?: { query?:UseQueryOptions<AsyncReturnType<typeof getHealth>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHealthQueryKey();

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getHealth>> = () => getHealth();

  const query = useQuery<AsyncReturnType<typeof getHealth>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getProtocols = (
    
 ) => {
      return authAxiosInstance<ProtocolsModel>(
      {url: `/protocols`, method: 'get'
    },
      );
    }
  

export const getGetProtocolsQueryKey = () => [`/protocols`];

    
export const useGetProtocols = <TData = AsyncReturnType<typeof getProtocols>, TError = unknown>(
  options?: { query?:UseQueryOptions<AsyncReturnType<typeof getProtocols>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetProtocolsQueryKey();

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getProtocols>> = () => getProtocols();

  const query = useQuery<AsyncReturnType<typeof getProtocols>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getSettings = (
    
 ) => {
      return authAxiosInstance<GetSettings200>(
      {url: `/settings`, method: 'get'
    },
      );
    }
  

export const getGetSettingsQueryKey = () => [`/settings`];

    
export const useGetSettings = <TData = AsyncReturnType<typeof getSettings>, TError = unknown>(
  options?: { query?:UseQueryOptions<AsyncReturnType<typeof getSettings>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetSettingsQueryKey();

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getSettings>> = () => getSettings();

  const query = useQuery<AsyncReturnType<typeof getSettings>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const getUser = (
    
 ) => {
      return authAxiosInstance<CurrentUserModel>(
      {url: `/user`, method: 'get'
    },
      );
    }
  

export const getGetUserQueryKey = () => [`/user`];

    
export const useGetUser = <TData = AsyncReturnType<typeof getUser>, TError = ProblemDetails>(
  options?: { query?:UseQueryOptions<AsyncReturnType<typeof getUser>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetUserQueryKey();

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getUser>> = () => getUser();

  const query = useQuery<AsyncReturnType<typeof getUser>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const patchUser = (
    updateUserModel: UpdateUserModel,
 ) => {
      return authAxiosInstance<CurrentUserModel>(
      {url: `/user`, method: 'patch',
      data: updateUserModel
    },
      );
    }
  


    export const usePatchUser = <TError = ProblemDetails,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof patchUser>, TError,{data: UpdateUserModel}, TContext>, }
) => {
      const {mutation: mutationOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof patchUser>, {data: UpdateUserModel}> = (props) => {
          const {data} = props || {};

          return  patchUser(data,)
        }

      return useMutation<AsyncReturnType<typeof patchUser>, TError, {data: UpdateUserModel}, TContext>(mutationFn, mutationOptions)
    }
    
